import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
  FaChevronDown,
  FaSearch,
  FaRegStar,
} from "react-icons/fa";
import "./SurveyDetailed.scss";
import BarGraph from "../components/BarGraph";
import { getSurveyDetailed } from "../services/api.services";
import { AuthContext } from "../../../App";

const SurveyDetailed = () => {
  const { state, dispatch } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const [selectedTab, setSelectedTab] = useState("overview");
  const [isDropdown, setIsDropdown] = useState(false);
  const [overallData, setOverallData] = useState({});
  const [questionResults, setQuestionResults] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedPercentages, setSelectedPercentages] = useState(null);
  const [colorData, setColorData] = useState([]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  useEffect(() => {
    const fetchSurveyDetailedData = async () => {
      const response = await getSurveyDetailed(header, data.id);

      if (response.status === 200) {
        const questionResults = response.data.question_results;

        setOverallData(response.data.overall_results);
        setResponseData(response.data.response_data);
        setQuestionResults(questionResults);

        const color = Object.values(response.data.overall_results).map(
          getColorByRating
        );
        setColorData(color);

        // Set the first question and its percentages as selected
        if (questionResults.length > 0) {
          setSelectedQuestion(questionResults[0]); // Set the first question
          setSelectedPercentages(questionResults[0].percentages); // Set its percentages
        }
      } else {
        console.error(response.message);
      }
    };

    fetchSurveyDetailedData();
  }, []);
  const getColorByRating = (rating) => {
    if (rating === 0) {
      return { primary: "red", secondary: "#FFDBDB" };
    } else if (rating >= 5) {
      return { primary: "green", secondary: "white" };
    } else if (rating >= 4) {
      return { primary: "green", secondary: "#CCF7D8" };
    } else if (rating >= 3) {
      return { primary: "orange", secondary: "#FFF0D3" };
    } else if (rating >= 2) {
      return { primary: "red", secondary: "#FFDBDB" };
    } else if (rating >= 1) {
      return { primary: "red", secondary: "#FFDBDB" };
    }
    return { primary: "grey", secondary: "#E0E0E0" };
  };
  const getRatingTextFromScore = (score) => {
    if (score >= 5) return "Outstanding";
    if (score >= 4) return "Good";
    if (score >= 3) return "Requires Improvement";
    return "Inadequate";
  };
  const getRatingText = (key) => {
    const score = overallData[key];
    return getRatingTextFromScore(score);
  };

  const getAverageRatingText = () => {
    const scores = Object.values(overallData);
    const averageScore =
      scores.reduce((acc, score) => acc + score, 0) / scores.length;
    return getRatingTextFromScore(averageScore);
  };
  const getAverageRatingColor = () => {
    const scores = Object.values(overallData);
    const averageScore =
      scores.reduce((acc, score) => acc + score, 0) / scores.length;
    const color = getColorByRating(averageScore);
    return color.primary;
  };

  const surveyData = [
    {
      id: 1,
      name: "Peter",
      effective: 5,
      well_ed: 5,
      safe: 5,
      caring: 5,
      responsive: 5,
      comment: "Adipoli",
    },
    {
      id: 1,
      name: "Jack",
      safe: 0,
      effective: 1,
      well_ed: 0.5,
      caring: 0.2,
      responsive: -1,
      comment: "Shokam",
    },
    {
      id: 1,
      name: "Jill",
      safe: 2,
      effective: 3,
      well_ed: 2,
      caring: 1,
      responsive: 3,
      comment: "Not Good",
    },
  ];
  const handleSelectQuestion = (questionItem) => {
    setSelectedQuestion(questionItem);
    setSelectedPercentages(questionItem.percentages);
  };
  return (
    <div className="admin_survey_detailed">
      <div className="admin_survey_detailed-top">
        <div
          className="admin_survey_detailed-top-icon"
          onClick={() => navigate("/compliance/admin/survey/")}
        >
          <FaArrowLeft />
        </div>
        <div className="admin_survey_detailed-top-title">
          <div className="admin_survey_detailed-top-title-text">Survey</div>/
          <div className="admin_survey_detailed-top-title-template_name">
            {data.name}
          </div>
        </div>
      </div>
      <div className="admin_survey_detailed-tabs">
        <div
          className={`admin_survey_detailed-tabs-tab ${
            selectedTab === "overview" ? "active" : ""
          }`}
          onClick={() => handleTabClick("overview")}
        >
          Overview
        </div>
        <div
          className={`admin_survey_detailed-tabs-tab ${
            selectedTab === "responses" ? "active" : ""
          }`}
          onClick={() => handleTabClick("responses")}
        >
          Responses
        </div>
      </div>
      <div className="admin_survey_detailed-body">
        {selectedTab === "overview" ? (
          <div className="admin_survey_detailed-body-overview">
            <div className="admin_survey_detailed-body-overview-overall">
              <div className="admin_survey_detailed-body-overview-overall-title">
                Overall Result:
              </div>
              <div className="admin_survey_detailed-body-overview-overall-body">
                <div className="admin_survey_detailed-body-overview-overall-body-graph">
                  <BarGraph
                    data={overallData}
                    stepValue={1}
                    colorData={colorData}
                  />
                </div>
                <div className="admin_survey_detailed-body-overview-overall-body-info">
                  <div className="admin_survey_detailed-body-overview-overall-body-info-title">
                    <div className="admin_survey_detailed-body-overview-overall-body-info-title-text">
                      Overall
                    </div>
                    :
                    <div
                      className="admin_survey_detailed-body-overview-overall-body-info-title-rating"
                      style={{ color: getAverageRatingColor() }}
                    >
                      {getAverageRatingText()}
                    </div>
                  </div>
                  <div className="admin_survey_detailed-body-overview-overall-body-info-legend">
                    <div
                      className="admin_survey_detailed-body-overview-overall-body-info-legend-item"
                      style={{ color: colorData[0]?.primary }}
                    >
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-color"
                          style={{
                            border: `1px solid ${colorData[0]?.primary}`,
                            backgroundColor: colorData[0]?.secondary,
                          }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-text">
                          Safe
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-value">
                        {getRatingText("Safe")}
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-overall-body-info-legend-item"
                      style={{ color: colorData[1]?.primary }}
                    >
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-color"
                          style={{
                            border: `1px solid ${colorData[1]?.primary}`,
                            backgroundColor: colorData[1]?.secondary,
                          }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-text">
                          Effective
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-value">
                        {getRatingText("Effective")}
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-overall-body-info-legend-item"
                      style={{ color: colorData[2]?.primary }}
                    >
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-color"
                          style={{
                            border: `1px solid ${colorData[2]?.primary}`,
                            backgroundColor: colorData[2]?.secondary,
                          }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-text">
                          Well-ed
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-value">
                        {getRatingText("Well-ed")}
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-overall-body-info-legend-item"
                      style={{ color: colorData[3]?.primary }}
                    >
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-color"
                          style={{
                            border: `1px solid ${colorData[3]?.primary}`,
                            backgroundColor: colorData[3]?.secondary,
                          }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-text">
                          Caring
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-value">
                        {getRatingText("Caring")}
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-overall-body-info-legend-item"
                      style={{ color: colorData[4]?.primary }}
                    >
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-color"
                          style={{
                            border: `1px solid ${colorData[4]?.primary}`,
                            backgroundColor: colorData[4]?.secondary,
                          }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-overall-body-info-legend-item-key-text">
                          Responsive
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-overall-body-info-legend-item-value">
                        {getRatingText("Responsive")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin_survey_detailed-body-overview-graph_info">
              <div className="admin_survey_detailed-body-overview-graph_info-item">
                <div className="admin_survey_detailed-body-overview-graph_info-item-key">
                  x-axis
                </div>
                :
                <div className="admin_survey_detailed-body-overview-graph_info-item-value">
                  Rating
                </div>
              </div>
              <div className="admin_survey_detailed-body-overview-graph_info-item">
                <div className="admin_survey_detailed-body-overview-graph_info-item-key">
                  y-axis
                </div>
                :
                <div className="admin_survey_detailed-body-overview-graph_info-item-value">
                  Category
                </div>
              </div>
            </div>
            <div className="admin_survey_detailed-body-overview-divided">
              <div className="admin_survey_detailed-body-overview-divided-body">
                <div className="admin_survey_detailed-body-overview-divided-body-graph">
                  {selectedPercentages && (
                    <BarGraph data={selectedPercentages} stepValue={20} />
                  )}
                </div>
                <div className="admin_survey_detailed-body-overview-divided-body-info">
                  <div className="admin_survey_detailed-body-overview-divided-body-info-filter">
                    <div className="admin_survey_detailed-body-overview-divided-body-info-filter-title">
                      Show results for question:
                    </div>
                    <div
                      className={`admin_survey_detailed-body-overview-divided-body-info-filter-dropdown ${
                        isDropdown ? "open" : ""
                      }`}
                      onClick={() => setIsDropdown(!isDropdown)}
                    >
                      <FaSearch className="admin_survey_detailed-body-overview-divided-body-info-filter-dropdown-search" />
                      {selectedQuestion
                        ? selectedQuestion.question
                        : "Choose from below"}
                      <FaChevronDown className="admin_survey_detailed-body-overview-divided-body-info-filter-dropdown-chevron" />
                      <div
                        className={`admin_survey_detailed-body-overview-divided-body-info-filter-dropdown-menu ${
                          isDropdown ? "open" : ""
                        }`}
                      >
                        {questionResults &&
                          questionResults.map((item, index) => (
                            <div
                              className="admin_survey_detailed-body-overview-divided-body-info-filter-dropdown-menu-item"
                              key={index}
                              onClick={() => handleSelectQuestion(item)}
                            >
                              {item.question}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="admin_survey_detailed-body-overview-divided-body-info-legend">
                    <div className="admin_survey_detailed-body-overview-divided-body-info-legend-title">
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-title-text">
                        Overall
                      </div>
                      :
                      <div
                        className="admin_survey_detailed-body-overview-divided-body-info-legend-title-rating"
                        style={{
                          color: selectedPercentages
                            ? getColorByRating(selectedQuestion.overall_rating)
                                .primary
                            : undefined,
                        }}
                      >
                        {selectedPercentages &&
                          getRatingTextFromScore(selectedQuestion.overall_rating)}
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-divided-body-info-legend-item"
                      style={{ color: "green" }}
                    >
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key">
                        <span className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-color">
                          <FaRegStar />
                        </span>
                        <span className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-text">
                          Outstanding
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-value">
                      {selectedQuestion && selectedQuestion.percentages["5"]}%
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-divided-body-info-legend-item"
                      style={{ color: "green" }}
                    >
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-color"
                          style={{ background: "green" }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-text">
                          Good
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-value">
                      {selectedQuestion && selectedQuestion.percentages["4"]}%
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-divided-body-info-legend-item"
                      style={{ color: "orange" }}
                    >
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-color"
                          style={{ background: "orange" }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-text">
                          Requires Improvement
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-value">
                      {selectedQuestion && selectedQuestion.percentages["3"]}%
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-divided-body-info-legend-item"
                      style={{ color: "red" }}
                    >
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-color"
                          style={{ background: "red" }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-text">
                          Inadequate
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-value">
                      {selectedQuestion && selectedQuestion.percentages["2"]}%
                      </div>
                    </div>
                    <div
                      className="admin_survey_detailed-body-overview-divided-body-info-legend-item"
                      style={{ color: "red" }}
                    >
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key">
                        <span
                          className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-color"
                          style={{ background: "red" }}
                        ></span>
                        <span className="admin_survey_detailed-body-overview-divided-body-info-legend-item-key-text">
                          Inadequate
                        </span>
                      </div>
                      -
                      <div className="admin_survey_detailed-body-overview-divided-body-info-legend-item-value">
                      {selectedQuestion && selectedQuestion.percentages["1"]}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin_survey_detailed-body-overview-graph_info">
              <div className="admin_survey_detailed-body-overview-graph_info-item">
                <div className="admin_survey_detailed-body-overview-graph_info-item-key">
                  x-axis
                </div>
                :
                <div className="admin_survey_detailed-body-overview-graph_info-item-value">
                  Total Response Percentages
                </div>
              </div>
              <div className="admin_survey_detailed-body-overview-graph_info-item">
                <div className="admin_survey_detailed-body-overview-graph_info-item-key">
                  y-axis
                </div>
                :
                <div className="admin_survey_detailed-body-overview-graph_info-item-value">
                  Rating Values
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="admin_survey_detailed-body-table">
            <div className="admin_survey_detailed-body-table-head">
              <div className="admin_survey_detailed-body-table-head-item">
                Email
              </div>
              <div className="admin_survey_detailed-body-table-head-item">
                Safe
              </div>
              <div className="admin_survey_detailed-body-table-head-item">
                Effective
              </div>
              <div className="admin_survey_detailed-body-table-head-item">
                Well-ed
              </div>
              <div className="admin_survey_detailed-body-table-head-item">
                Caring
              </div>
              <div className="admin_survey_detailed-body-table-head-item">
                Responsive
              </div>
              <div className="admin_survey_detailed-body-table-head-item">
                Category
              </div>
            </div>
            <div className="admin_survey_detailed-body-table-body">
              {responseData &&
                responseData.map((item) => (
                  <div
                    className="admin_survey_detailed-body-table-body-row"
                    key={item.id}
                  >
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      <div className="admin_survey_detailed-body-table-body-row-item-text">
                        <div className="admin_survey_detailed-body-table-body-row-item-text-main">
                          {item.email}
                        </div>
                      </div>
                    </div>
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      {item.safe}
                    </div>
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      {item.effective}
                    </div>
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      {item.well_ed}
                    </div>
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      {item.caring}
                    </div>
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      {item.responsive}
                    </div>
                    <div className="admin_survey_detailed-body-table-body-row-item">
                      {item.category}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyDetailed;
