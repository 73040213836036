import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const createPolicy = async (header,formData) => {
  try {
    const res = await instance.post(
      "api/policy/admin/policies/",
      formData,
      {
        headers: {
          ...header,
          "Content-Type": "multipart/form-data", 
        },
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};


export const getPolicytData = async (header, page = 1, pageSize = 10) => {
  try {
    const url = `api/policy/admin/policies/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getOrganisationData = async (header) => {
  try {
    const url = "api/user/get_organisation_data/";
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

// export const getSurveyDetailed = async (header, id) => {
//   try {
//     const url = `api/compliance/admin/survey/${id}/`;
//     const res = await instance.get(url, {
//       headers: header,
//     });
//     return res;
//   } catch (err) {
//     return { status: "error", message: err };
//   }
// };


export const searchPolicyData = async (
  header,
  searchKey,
  page = 1,
  pageSize = 10
) => {
  try {
    const url = `api/policy/admin/policies/?page=${page}&page_size=${pageSize}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const removePolicyData = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/policy/admin/policies/bulk-delete/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getTemplateData = async (header, page = 1, pageSize = 10) => {
  try {
    const url = `api/policy/admin/policy-templates/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const searchTemplateData = async (
  header,
  searchKey,
  page = 1,
  pageSize = 10
) => {
  try {
    const url = `api/policy/admin/policy-templates/?page=${page}&page_size=${pageSize}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const removeTemplateData = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/policy/admin/policy-templates/bulk-delete/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};