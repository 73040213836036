import React, { useEffect, useState } from "react";
import "./AppSidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import courseProgress from "../../images/icons/dna_course.png";
import toast from "react-hot-toast";
import avatar from "../../images/icons/user_avatar.png";
import { FaTimes, FaChevronDown, FaChevronUp, FaChevronCircleDown, FaCheckCircle, FaRegCircle, FaLock } from "react-icons/fa";
import { useDashboardContext } from "../contexts/DashboardContext";

const AppSideBar = ({
  courseData,
  setSelectedNavbarData,
  setOpenMobileSidebarMenu,
  deviceWidth,
  openMobileSidebar,
}) => {
  const { setSelectedNavbarTab, selectedSidebarTab, setSelectedSidebarTab, selectedChapter, setSelectedChapter } = useDashboardContext();
  const [toggleState, setToggleState] = useState(false);
  const [toggleSideBar, setToggleSideBar] = useState(true);
  const [sidecollapse, setSideCollapse] = useState(false);
  const [openModules, setOpenModules] = useState({});
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (deviceWidth <= 800) {
      setSideCollapse(true);
    } else {
      setSideCollapse(false);
    }
  }, [deviceWidth]);

  useEffect(() => {
    handleSideBarToggle();
  }, [sidecollapse, openMobileSidebar]);

  useEffect(() => {
    const handleStorageChange = () => {
      setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("userDetailsUpdated", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("userDetailsUpdated", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (selectedSidebarTab) {
      const module = courseData.modules.find(mod => mod.name === selectedSidebarTab);
      if (module) {
        setOpenModules(prev => ({
          ...prev,
          [module.id]: true,
        }));
      }
    }
  }, [selectedSidebarTab, courseData]);

  useEffect(() => {
    if (selectedChapter) {
      // Ensure the module containing the chapter is open
      const moduleId = courseData.modules.find(mod => 
        mod.chapter.some(chap => chap.id === selectedChapter)
      )?.id;

      if (moduleId) {
        setOpenModules(prev => ({
          ...prev,
          [moduleId]: true,
        }));
      }
    }
  }, [selectedChapter, courseData]);

  const handleClickLink = async (courseId, courseName, moduleName) => {
    setSelectedNavbarTab(1);
    setSelectedSidebarTab(moduleName);
    navigate(`/${courseName}/${moduleName}`);
    // window.location.reload();
  };

  const handleSideBarToggle = () => {
    if (deviceWidth > 450) {
      if (sidecollapse) {
        if (toggleState) {
          document.getElementById("collapsible_sidebar").style.width = "30%";
          setToggleState(false);
          setToggleSideBar(true);
        } else {
          document.getElementById("collapsible_sidebar").style.width = "10%";
          setToggleState(true);
          setToggleSideBar(false);
        }
      } else {
        document.getElementById("collapsible_sidebar").style.width = "20%";
        setToggleSideBar(true);
      }
    } else {
      setToggleSideBar(true);
      if (openMobileSidebar) {
        document.getElementById("collapsible_sidebar").style.right = "0";
        document.getElementById("collapsible_sidebar").style.width = "200px";
      } else {
        document.getElementById("collapsible_sidebar").style.right = "-100%";
      }
    }
  };

  const handleCloseMobileSideBar = () => {
    setOpenMobileSidebarMenu(false);
  };

  const toggleModule = (moduleId) => {
    setOpenModules((prev) => ({
      ...prev,
      [moduleId]: !prev[moduleId],
    }));
  };

  const openAllModules = () => {
    const allOpen = courseData.modules.reduce((acc, module) => {
      acc[module.id] = true;
      return acc;
    }, {});
    setOpenModules(allOpen);
  };

  const closeAllModules = () => {
    const allClosed = courseData.modules.reduce((acc, module) => {
      acc[module.id] = false;
      return acc;
    }, {});
    setOpenModules(allClosed);
  };

  return (
    <div
      className={toggleState ? "app-sidebar" : "app-sidebar shadow"}
      id="collapsible_sidebar"
    >
      {sidecollapse && (
        <div
          className={
            toggleSideBar
              ? "app-sidebar_collapsed expand"
              : "app-sidebar_collapsed"
          }
        >
          <div
            className="app-sidebar_collapsed-hamburger"
            onClick={() => {
              if (deviceWidth < 450) {
                handleCloseMobileSideBar();
              } else {
                handleSideBarToggle();
              }
            }}
          >
            <span className="app-sidebar_collapsed-hamburger-bar"></span>
            <span className="app-sidebar_collapsed-hamburger-bar"></span>
            <span className="app-sidebar_collapsed-hamburger-bar"></span>
          </div>
        </div>
      )}
      {userDetails && (
        <div className="app-sidebar_user">
          <div
            className={
              toggleSideBar
                ? "app-sidebar_user-avatar expand"
                : "app-sidebar_user-avatar"
            }
          >
            <img
              src={userDetails.avatar ? userDetails.avatar : avatar}
              alt="userimage"
            ></img>
          </div>
          {toggleSideBar && (
            <div className="app-sidebar_user-details">
              <h3>
                {userDetails.first_name} {userDetails.last_name}
              </h3>
              <p>{userDetails.role}</p>
            </div>
          )}
        </div>
      )}
      {toggleSideBar && (
        <div className="app-sidebar_dynamic-selector">
          <h3>Modules</h3>
          <ul>
            {courseData &&
              courseData.modules.map((data) => (
                <li key={data.id}>
                  <div className="app-sidebar_dynamic-selector-course">
                    <div
                      className={
                        selectedSidebarTab === data.name
                          ? "app-sidebar_dynamic-selector-course-link selected"
                          : "app-sidebar_dynamic-selector-course-link"
                      }
                      onClick={() => {
                        handleClickLink(
                          courseData.id,
                          courseData.name,
                          data.name
                        );
                        toggleModule(data.id);
                      }}
                    >
                      <div className="app-sidebar_dynamic-selector-course-link-icon">
                        <img src={data.icon} alt="module-icon" />
                      </div>
                      <div className="app-sidebar_dynamic-selector-course-link-text">
                        {data.name}
                      </div>
                      {openModules[data.id] ? (
                      <FaChevronUp className="chevron-icon" />
                    ) : (
                      <FaChevronDown className="chevron-icon" />
                    )}
                    </div>
                    {openModules[data.id] && (
                      <div className="app-sidebar_dynamic-selector-course-module-chapters">
                        {data.chapter.map((chapter) => (
                          <div
                            key={chapter.id}
                            className={
                              chapter.progress !== null || chapter.position === 1
                                ? (selectedChapter && selectedChapter.id === chapter.id)
                                  ? "app-sidebar_dynamic-selector-course-module-chapters-chapter selected"
                                  : "app-sidebar_dynamic-selector-course-module-chapters-chapter "
                                : "app-sidebar_dynamic-selector-course-module-chapters-chapter locked"
                            }
                            onClick={() => {
                              if (chapter.progress !== null || chapter.position === 1) {
                                setSelectedChapter(chapter)
                                setSelectedSidebarTab(data.name)
                              } else {
                                toast.error("Complete the previous chapter first.");
                              }
                            }}
                          >
                            <div className="app-sidebar_dynamic-selector-course-module-chapters-chapter-icon">
                              {chapter.progress !== null || chapter.position === 1
                                ? chapter.is_complete
                                  ? <FaCheckCircle style={{ color: "green" }} />
                                  : <FaRegCircle  style={{ color: "orange" }}  />
                                : <FaLock />}
                            </div>
                            <div className="app-sidebar_dynamic-selector-course-module-chapters-chapter-text">
                              {chapter.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </li>
              ))}
          </ul>
          <div className="app-sidebar_dynamic-selector-controls">
            •<span onClick={openAllModules}>Open All</span>•
            <span onClick={closeAllModules}>Close All</span>•
          </div>
        </div>
      )}
    </div>
  );
};

export default AppSideBar;
