import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
  FaFileContract,
  FaCaretUp,
  FaCaretDown,
} from "react-icons/fa";
import "./CreatePolicy.scss";
import {
  getTemplateData,
  createPolicy,
  getOrganisationData,
} from "../services/api.services";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";

const CreatePolicy = () => {
  const { state } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const navigate = useNavigate();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [organisationData, setOrganisationData] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState("Select Template");
  const [formErrors, setFormErrors] = useState({});

  const [policyFormData, setPolicyFormData] = useState({
    policy_author: "",
    policy_approver: "",
    template: "",
  });

  const dropdownRef = useRef(null);

  useEffect(() => {
    fetchTemplateData();
    fetchOrganisationData();
  }, []);

  const fetchTemplateData = async () => {
    try {
      let response = await getTemplateData(header);
      if (response.status === 200) {
        setTemplateData(response.data.results);
      } else {
        toast.error("Failed to fetch Template data");
      }
    } catch (error) {
      toast.error("Error fetching template data: " + error.message);
    }
  };

  const fetchOrganisationData = async () => {
    try {
      let response = await getOrganisationData(header);
      if (response.status === 200) {
        setOrganisationData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch Template data");
      }
    } catch (error) {
      toast.error("Error fetching template data: " + error.message);
    }
  };

  const handleDropdownToggle = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template.name);
    setIsOpenDropdown(false);
    handleFormDataChange("template", template.id);
  };

  const handleFormDataChange = (field, value) => {
    setPolicyFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    // Remove the error when user starts typing in that field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: false,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormDataChange(name, value);
  };

  const handleSubmit = async () => {
    // Validate form fields
    const errors = {};
    if (!policyFormData.policy_author) errors.policy_author = true;
    if (!policyFormData.policy_approver) errors.policy_approver = true;
    if (!policyFormData.template) errors.template = true;

    setFormErrors(errors);

    // If no errors, proceed to submit
    if (Object.keys(errors).length === 0) {
      try {
        const formData = {
          policy_author: policyFormData.policy_author,
          policy_approver: policyFormData.policy_approver,
          template: policyFormData.template,
        };
        const response = await createPolicy(header, formData);

        if (response.status === 201) {
          toast.success("Policy created successfully!");
          navigate("/policy/admin/policy/");
        } else {
          toast.error("Failed to create policy.");
        }
      } catch (error) {
        toast.error("Error creating policy: " + error.message);
      }
    }
  };

  return (
    <div className="create-policy">
      <div className="create-policy-top">
        <div
          className="create-policy-top-icon"
          onClick={() => navigate("/policy/admin/policy/")}
        >
          <FaArrowLeft />
        </div>
        <div className="create-policy-top-title">
          <div className="create-policy-top-title-text">New Policy</div>
        </div>
      </div>
      <div className="create-policy-body">
        <div className="create-policy-body-title">
          Create Policy <FaFileContract />
        </div>

        <div className="create-policy-body-form">
          {organisationData && (
            <div className="create-policy-body-form-org">
              <div className="create-policy-body-form-org-profile"
              style={organisationData.logo ? { backgroundColor: "transparent" } : {}}
              >
                {organisationData.logo ? (
                  <img
                    src={organisationData.logo}
                    alt={organisationData.name}
                    className="create-policy-body-form-org-logo"
                  />
                ) : (
                  organisationData.shortname
                )}
              </div>
              <div className="create-policy-body-form-org-name">
                {organisationData.name}
              </div>
              <div className="create-policy-body-form-org-email">
                {organisationData.admin_email}
              </div>
            </div>
          )}

          <div className="create-policy-body-form-inputs">
            <div
              className={`create-policy-body-form-inputs-input ${
                formErrors.policy_author ? "error" : ""
              }`}
            >
              <label>Policy Author</label>
              <input
                type="text"
                name="policy_author"
                value={policyFormData.policy_author}
                onChange={handleInputChange}
                style={{ background: "white" }}
              />
            </div>

            <div
              className={`create-policy-body-form-inputs-input ${
                formErrors.policy_approver ? "error" : ""
              }`}
            >
              <label>Policy Approver</label>
              <input
                type="text"
                name="policy_approver"
                value={policyFormData.policy_approver}
                onChange={handleInputChange}
              />
            </div>

            <div
              className={`create-policy-body-form-inputs-input ${
                formErrors.template ? "error" : ""
              }`}
              onClick={handleDropdownToggle}
              ref={dropdownRef}
            >
              <label>Template</label>
              <span>
                {selectedTemplate}
                {isOpenDropdown ? <FaCaretUp /> : <FaCaretDown />}
              </span>
              {isOpenDropdown && (
                <div className="dropdown-list">
                  {templateData &&
                    templateData.map((template) => (
                      <div
                        key={template.id}
                        className="dropdown-item"
                        onClick={() => handleSelectTemplate(template)}
                      >
                        {template.name}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="create-policy-body-bottom">
          <button className="primary-button" onClick={handleSubmit}>
            Create Policy
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePolicy;
