import React, { useState } from "react";
import { Bubble, Bar, Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import AppLoader from "../../../learning/user/components/AppLoader";
import completionIcon from "../images/completed.png"
import pendingIcon from "../images/pending.png"
import overdueIcon from "../images/overdue.png"
import breachIcon from "../images/breach.png"
import "./AdminDashboardElements.scss";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const PolicyDashboardElements = () => {
  const bubbleChartData = {
    datasets: [
      {
        label: "Frequently Accessed Policies",
        data: [
          { x: 1, y: 5, r: 15, label: "Patient Privacy Policy" },
          { x: 2, y: 4, r: 20, label: "Infection Control Policy" },
          { x: 3, y: 2, r: 25, label: "Emergency Triage Policy" },
          { x: 4, y: 3, r: 30, label: "Medication Administration Policy" },
          { x: 5, y: 6, r: 20, label: "Patient Consent Policy" },
          { x: 6, y: 5, r: 18, label: "Fall Prevention Policy" },
          { x: 7, y: 4, r: 22, label: "Patient Safety Reporting Policy" },
        ],
        backgroundColor: "#4db8c1",
        hoverBackgroundColor: "#4db8c1",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            // Get the index of the hovered item
            const index = tooltipItems[0].dataIndex;
            // Return the label of the specific policy
            return bubbleChartData.datasets[0].data[index].label;
          },
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const [dashboardData, setDashboardData] = useState({
    traineeCompletion: 85, // Trainee completion percentage
    pendingRevisions: 5, // Number of pending policy revisions
    overdueRevisions: 3, // Number of overdue revisions
    policyBreaches: 4, // Number of policy breaches
    monthlyBreachesData: {
      // Monthly policy breaches
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Policy Breaches",
          data: [1, 3, 2, 5, 4, 6, 2, 1, 0, 3, 2, 4], // Example data for each month
          borderColor: "#569cd6",
          backgroundColor: "#569cd6",
          fill: true,
        },
      ],
    },
    traineeCompletionData: {
      labels: ["Completed", "Pending", "Overdue"],
      datasets: [
        {
          label: "Trainee Completion",
          data: [60, 25, 15], // Values for completed, pending, and overdue
          backgroundColor: ["#4db8c1", "#569cd6", "#05167c"],
          hoverBackgroundColor: ["#4db8c1", "#569cd6", "#05167c"],
        },
      ],
    },
  });

  const [loader, setLoader] = useState(false);

  return (
    <>
      {loader ? (
        <div className="policy_admin_dashboard-loader">
          <AppLoader />
        </div>
      ) : (
        <div className="policy_admin_dashboard-elements">
          <div className="policy_admin_dashboard-elements-title">
            Policy Dashboard
          </div>
          <div className="policy_admin_dashboard-elements_body">
            <div className="policy_admin_dashboard-elements_body-appInfo">
              <div className="policy_admin_dashboard-elements_body-appInfo-container">
                <div className="policy_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-title">
                    Trainee Completion
                  </span>
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.traineeCompletion}%
                  </span>
                </div>
                <div className="policy_admin_dashboard-elements_body-appInfo-container-icon trainee-icon">
                  <img src={completionIcon} alt="completionIcon"></img>
                </div>
              </div>
              <div className="policy_admin_dashboard-elements_body-appInfo-container">
                <div className="policy_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-title">
                    Pending Policy Revisions
                  </span>
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.pendingRevisions}
                  </span>
                </div>
                <div className="policy_admin_dashboard-elements_body-appInfo-container-icon course-icon">
                  <img src={pendingIcon} alt="pendingIcon"></img>
                </div>
              </div>
              <div className="policy_admin_dashboard-elements_body-appInfo-container">
                <div className="policy_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-title">
                    Overdue Revisions
                  </span>
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.overdueRevisions}
                  </span>
                </div>
                <div className="policy_admin_dashboard-elements_body-appInfo-container-icon credit-icon">
                  <img src={overdueIcon} alt="overdueIcon"></img>
                </div>
              </div>
              <div className="policy_admin_dashboard-elements_body-appInfo-container">
                <div className="policy_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-title">
                    Policy Breaches
                  </span>
                  <span className="policy_admin_dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.policyBreaches}
                  </span>
                </div>
                <div className="policy_admin_dashboard-elements_body-appInfo-container-icon plan-icon">
                  <img src={breachIcon} alt="breachIcon"></img>
                </div>
              </div>
            </div>

            {/* Insights Section */}
            <div className="policy_admin_dashboard-elements_body-insights">
              <div className="policy_admin_dashboard-elements_body-insights-title">
                Policy Insights
              </div>
              <div className="policy_admin_dashboard-elements_body-insights-charts">
                <div
                  className="policy_admin_dashboard-elements_body-insights-charts-container"
                >
                  {/* Donut Chart for Trainee Completion Status */}
                  <div
                    className="policy_admin_dashboard-elements_body-insights-charts-container-chart"
                    style={{ width: "400px" }}
                  >
                    <Doughnut data={dashboardData.traineeCompletionData} />
                  </div>
                </div>
                <div
                  className="policy_admin_dashboard-elements_body-insights-charts-container"
                  style={{ display: "flex", gap: "20px", flexWrap: "wrap", width:"400px" }}
                >
                  {/* Line Graph for Monthly Policy Breaches */}
                  <div
                    className="policy_admin_dashboard-elements_body-insights-charts-container-chart"
                    style={{ width: "400px" }}
                  >
                    <Line data={dashboardData.monthlyBreachesData} />
                  </div>

                  {/* Bubble Chart for Frequently Accessed Policies */}
                  <div
                    className="policy_admin_dashboard-elements_body-insights-charts-container-chart"
                    style={{ width: "400px" }}
                  >
                    <Bubble data={bubbleChartData} options={options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PolicyDashboardElements;
