import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const createSurvey = async (header, formData) => {
  try {
    const res = await instance.post(
      "api/compliance/admin/survey/",
      formData,
      
        {
          headers: header,
        },
      
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getSurveyData = async (header, page = 1, pageSize = 10) => {
  try {
    const url = `api/compliance/admin/survey/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getSurveyDetailed = async (header, id) => {
  try {
    const url = `api/compliance/admin/survey/${id}/`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};


export const searchSurveyData = async (
  header,
  searchKey,
  page = 1,
  pageSize = 10
) => {
  try {
    const url = `api/compliance/admin/survey/?page=${page}&page_size=${pageSize}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const removeSurveyData = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/compliance/admin/survey/bulk-delete/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const createRecipientList = async (header, name, file) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("excel", file);

  try {
    const res = await instance.post(
      "api/compliance/admin/recipient-list/",
      formData,
      {
        headers: {
          ...header,
          "Content-Type": "multipart/form-data", 
        },
      }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getRecipientListData = async (header, page = 1, pageSize = 10) => {
  try {
    const url = `api/compliance/admin/recipient-list/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const searchRecipientList = async (
  header,
  searchKey,
  page = 1,
  pageSize = 10
) => {
  try {
    const url = `api/compliance/admin/recipient-list/?page=${page}&page_size=${pageSize}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateRecipientListData = async (
  header,
  recipientListId,
  updatedData
) => {
  try {
    const res = await instance.patch(
      `api/compliance/admin/recipient-list/${recipientListId}/`,
      updatedData,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const removeRecipientList = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/compliance/admin/recipient-list/bulk-delete/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const downloadRecipientTemplate = async (header) => {
  try {
    const res = await instance.get("api/compliance/admin/recipient-list/get-recipient-template/", {
      headers: header,
      responseType: "blob",
    });
    return res;
  } catch (err) {
    console.log("Error downloading template:", err);
    return { status: "error", message: err };
  }
};

export const getTemplateData = async (header, page = 1, pageSize = 10) => {
  try {
    const url = `api/compliance/admin/survey-template/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const getTemplateQuestionData = async (header, id) => {
  try {
    const url = `api/compliance/admin/survey-template/${id}/`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const searchTemplateData = async (
  header,
  searchKey,
  page = 1,
  pageSize = 10
) => {
  try {
    const url = `api/compliance/admin/survey-template/?page=${page}&page_size=${pageSize}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const removeTemplateData = async (header, requestIds) => {
  try {
    const res = await instance.post(
      "api/compliance/admin/survey-template/bulk-delete/",
      { request_ids: requestIds },
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};